import FormCard from "../FormCard";
import { useEffect, useState } from "react";

const EditPerkView = ({ perkTierVisible }) => {
  const [subscribed, setSubscribed] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <div className="xl:ml-80">
      <section className="bg-white p-8 pb-6">
        <div className="flex flex-wrap items-center justify-between pb-6 -mx-2">
          <div className="flex justify-between w-full px-2">
            <h2 className="text-lg font-semibold">Edit Perk</h2>
            {perkTierVisible && (
              <button
                onClick={() => setSubscribed(!subscribed)}
                className="px-4 py-2 bg-violet-500 hover:bg-violet-600 font-medium text-sm text-white border border-violet-500 rounded-md shadow-button"
              >
                {subscribed ? "Back to Edit Perk" : "Manage Perk Tiers"}
              </button>
            )}
          </div>
        </div>
        <div className="border-b border-coolGray-100" />
      </section>
      <section className="bg-coolGray-50 py-4">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -m-3 justify-center">
            <FormCard
              title="Add or Edit Tiers"
              description="Please allow up to 24 hours for us to publish your perk tier changes. Make sure to specify whether you’re adding or editing an existing tier. If editing an existing tier, make sure to specify the correct tier."
              subscribed={subscribed}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditPerkView;

import React from "react";

const ExternalLinkTag = ({ title, url }) => {
  return (
    <a
      target="_blank"
      href={url}
      className="px-3 py-1 mb-6 bg-violet-500 hover:bg-violet-600 font-medium text-xs text-white border border-violet-500 rounded-full shadow-button"
    >
      <p>{title}</p>
    </a>
  );
};

export default ExternalLinkTag;

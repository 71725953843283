import KPIRow from '../../Analytics/KPIRow';
import ContactDataTable from '../../Analytics/ContactDataTable';

const RedemptionsDashboardView = ({ contacts, perkTierVisible }) => {

    const calculateTotalRedemptions = (contacts) => {
      return contacts.length;
    };

    const calculateAverageTeamSize = (contacts) => {
        const totalTeamSize = contacts.reduce((acc, curr) => acc + (curr.teamSize || 0), 0);
        return contacts.length > 0 ? (totalTeamSize / contacts.length).toFixed(2) : 0;
    };

    const calculateAverageCompanyAge = (contacts) => {
        const totalAge = contacts.reduce((acc, curr) => acc + (curr.age || 0), 0);
        return contacts.length > 0 ? `${(totalAge / contacts.length).toFixed(2)} years` : '0 years';
    };

    return (
      <div className='xl:ml-80'>
        <section className='bg-coolGray-50 py-4'>
          <div className='container px-4 mx-auto'>
            <div className='p-6 h-full border border-coolGray-100 overflow-hidden bg-white rounded-md shadow-dashboard'>
              <div className='pb-6 border-b border-coolGray-100'>
                <div className='flex flex-wrap items-center justify-between -m-2'>
                  <div className='w-full md:w-auto p-2'>
                    <h2 className='text-coolGray-900 text-lg font-semibold'>
                      Redemptions
                    </h2>
                    <p className='text-xs text-coolGray-500 font-medium'>
                      View redemptions of your perk.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className='py-6 border-b border-coolGray-100'> */}
              <KPIRow data={[
                {title: 'Total Redemptions', value: calculateTotalRedemptions(contacts)},
                {title: 'Average Team Size', value: calculateAverageTeamSize(contacts)},
                {title: 'Average Company Age', value: calculateAverageCompanyAge(contacts)},
              ]}/>
              <ContactDataTable contacts={contacts} perkTierVisible={perkTierVisible}/>
              {/* </div> */}
            </div>
          </div>
        </section>
      </div>
        
    )
}

export default RedemptionsDashboardView;
import { useContext, useState } from "react";
import ResourceContext from "../contexts/ResourceContext";
import SelectionContext from "../contexts/SelectionContext";
import ExternalLinkTag from "./ExternalLinkTag";

const PerkCard = ({ perk, excluded, isEmbedded, handleView }) => {
  const [resource, resourceDispatch] = useContext(ResourceContext);
  const [selections, selectionsDispatch] = useContext(SelectionContext);
  const [isSelected, setIsSelected] = useState(!excluded);
  const [isHovered, setIsHovered] = useState(false);

  const handleToggle = () => {
    setIsSelected(!isSelected);
    selectionsDispatch({
      type: "TOGGLE_PERK",
      payload: {
        perk,
        isSelected: !isSelected,
      },
    });
  };

  const hoveredStyles = {
    transform: isHovered ? "scale(0.95)" : "scale(1)",
    boxShadow: isHovered ? "0px 2px 4px rgba(0, 0, 0, 0.1)" : "none",
  };

  return (
    <div className="w-full md:w-1/3 p-3 flex">
      <div
        className="bg-white border border-coolGray-100 shadow-dashboard rounded-md w-full"
        style={{
          transition: "transform 0.3s, box-shadow 0.3s",
          ...hoveredStyles,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <label
          className="flex flex-col px-4 pt-8 pb-6 border-b border-coolGray-100"
          style={{ height: "100%", cursor: "pointer" }}
        >
          <div
            className="flex w-full justify-end items-center"
            style={{ paddingTop: "1rem", gap: "10px" }}
          >
            {!isEmbedded && !perk?.userTier && perk?.perkTier && (
              <ExternalLinkTag
                title="Apply to upgrade"
                url={perk.perk_upgrade_url}
              />
            )}
            {isEmbedded && !perk.featured ? null : perk.featured ? (
              <p className="px-3 py-1 mb-6 text-xs text-violet-500 font-medium bg-violet-100 border border-violet-500 rounded-full shadow-sm">
                Featured
              </p>
            ) : (
              <input
                type="checkbox"
                checked={isSelected}
                onChange={handleToggle}
                style={{ width: "16px", height: "16px", cursor: "pointer" }}
              />
            )}
          </div>

          <div className="flex justify-center">
            <img
              style={{ paddingTop: "20px" }}
              className="mb-4"
              src={perk.icon_url}
              alt=""
            />
          </div>
          <div className="flex justify-center">
            <h2 className="text-lg font-medium text-coolGray-900">
              {perk.name}
            </h2>
          </div>
          <h3 style={{ paddingTop: "20px" }}>{perk.description_excerpt}</h3>
          <div
            className="perk-info"
            style={{
              marginTop: "auto",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <div>
              <h2 style={{ display: "inline-block" }}>
                <strong>Offer:&nbsp;</strong>
              </h2>
              <h2 style={{ display: "inline-block" }}>{perk.perk}</h2>
            </div>
            <div>
              <h2 style={{ display: "inline-block" }}>
                <strong>Est. Value:&nbsp;</strong>
              </h2>
              <h2 style={{ display: "inline-block" }}>
                {Number(perk.value).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </h2>
            </div>
          </div>
          <button
            className="flex items-center justify-center px-4 py-2 font-medium text-sm text-white bg-violet-500 hover:bg-violet-600 rounded-md"
            onClick={() => {
              resourceDispatch({
                type: "setResource",
                payload: { type: "Perk", resource: perk },
              });
              isEmbedded && handleView();
            }}
          >
            <p>View</p>
          </button>
        </label>
      </div>
    </div>
  );
};

export default PerkCard;

import { useContext, useState, useEffect } from "react";
import { supabase } from "../../services/supabase/supabaseClient";
import { useAuth } from "../../contexts/AuthProvider";

import SelectionContext from "../../contexts/SelectionContext";

import PerkList from "../PerkList";

const Perks = () => {
  const [loading, setLoading] = useState(true);
  const [selections, selectionsDispatch] = useContext(SelectionContext);
  const [perks, setPerks] = useState([]);
  const [excludedPerks, setExcludedPerks] = useState([]);
  const [saveButtonText, setSaveButtonText] = useState("Save Selections");
  const { user } = useAuth();

  useEffect(() => {
    const fetchPerks = async () => {
      setLoading(true);
      try {
        // Fetch user-specific perk tiers first
        const { data: userPerkTiers, error: userPerkTiersError } =
          await supabase
            .from("user_perk_tiers")
            .select("perk_tier_id, perk_tiers!inner(*)")
            .eq("user_id", user.id);

        if (userPerkTiersError) {
          throw userPerkTiersError;
        }

        // Fetch all the perks tiers
        const { data: perkTiersData, error: perksTiersError } = await supabase
          .from("perk_tiers")
          .select("*");

        if (perksTiersError) {
          throw perksTiersError;
        }

        // Fetch perks associated with the user's specific tiers
        const { data: perksData, error: perksError } = await supabase
          .from("perks")
          .select("*")
          .order("featured", { ascending: false })
          .order("name", { ascending: true });

        if (perksError) {
          throw perksError;
        }

        // Merge userPerkTiers data into perksData
        const mergedPerksData = perksData.map((perk) => {
          // Find a matching perk in userPerkTiers by perk_id
          const matchingUserPerkTier = userPerkTiers.find(
            (userPerk) => userPerk.perk_tiers.perk_id === perk.id
          );

          // Find matching perk tier
          const matchingPerkTier = perkTiersData.find(
            (perkTier) => perkTier.perk_id === perk.id
          );

          // If a match is found in userPerkTiers, merge/replace fields
          if (matchingUserPerkTier) {
            return {
              ...perk,
              ...matchingUserPerkTier.perk_tiers,
              name: perk.name,
              featured: perk.featured,
              userTier: true,
              ...(matchingPerkTier && { perkTier: true }),
            };
          }

          // Return the original perk if no match is found
          return {
            ...perk,
            ...(matchingPerkTier && { perkTier: true }),
          };
        });

        // Fetch exclusions for the logged-in user
        const { data: exclusionData, error: exclusionError } = await supabase
          .from("resource_exclusions")
          .select("resource_id")
          .eq("user_id", user.id)
          .eq("resource_type", "perk");

        if (exclusionError) {
          throw exclusionError;
        }

        const excludedPerkIds = exclusionData.map(
          (exclusion) => exclusion.resource_id
        );

        // Set the merged perks data
        setPerks(mergedPerksData);
        setExcludedPerks(excludedPerkIds);
      } catch (error) {
        console.error("Error fetching perks:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPerks();
  }, [selections]);

  if (loading) {
    return;
  }

  const handleSaveSelections = async () => {
    setSaveButtonText("Saving...");

    const deselectedPerks = selections.deselectedPerks;
    const selectedPerks = selections.selectedPerks;

    // Insert deselected perks into the resource_exclusions table
    for (const perk of deselectedPerks) {
      // Check if the perk ID is not in the excludedPerks list
      if (!excludedPerks.includes(perk.id)) {
        await supabase
          .from("resource_exclusions")
          .insert([{ resource_id: perk.id, resource_type: "perk" }]);
      }
    }

    // For selected perks, remove those rows from resource_exclusions
    for (const perk of selectedPerks) {
      // Check if the perk ID is in the excludedPerks list
      if (excludedPerks.includes(perk.id)) {
        // Delete rows where resource_id is the selected perk ID and resource_type is 'perk'
        await supabase
          .from("resource_exclusions")
          .delete()
          .eq("resource_id", perk.id)
          .eq("resource_type", "perk")
          .eq("user_id", user.id);
      }
    }

    selectionsDispatch({ type: "REMOVE_PERK_SELECTIONS" });

    setTimeout(() => {
      setSaveButtonText("Save Selections");
    }, 1000);
  };

  return (
    <div className="xl:ml-80">
      <section className="bg-white p-8 pb-6">
        <div className="flex flex-wrap items-center justify-between pb-6 -mx-2">
          <div className="w-auto px-2">
            <h2 className="text-lg font-semibold">Select Perks</h2>
          </div>
          <div className="w-auto px-2">
            <button
              onClick={handleSaveSelections}
              className="flex flex-wrap justify-center w-full px-4 py-2 bg-violet-500 hover:bg-violet-600 font-medium text-sm text-white border border-violet-500 rounded-md shadow-button"
            >
              <p>{saveButtonText}</p>
            </button>
          </div>
        </div>
        <div className="border-b border-coolGray-100" />
      </section>
      <section className="bg-coolGray-50 py-4">
        <div className="container px-4 mx-auto">
          {/* Only apply justify-center to resource details views */}
          <div className="flex flex-wrap -m-3">
            <PerkList perks={perks} excludedPerks={excludedPerks} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Perks;

import ContactDataTableRow from "./ContactDataTableRow";

const ContactRowList = ({ contacts, perkTierVisible }) => {
  return contacts.map((contact, index) => (
    <ContactDataTableRow
      key={`${contact.email}-${index}`}
      redeemedAt={contact.submittedAt}
      name={contact.firstName + " " + contact.lastName}
      initials={contact.firstName.charAt(0) + contact.lastName.charAt(0)}
      email={contact.email}
      company={contact.company}
      country={contact.country}
      founded={contact.yearFounded}
      teamSize={contact.teamSize}
      websiteUrl={contact.websiteUrl}
      tier={contact?.perks?.[0]?.perk_tiers?.name ?? ""}
      networkPartner={contact.networkPartner}
      perkTierVisible={perkTierVisible}
    />
  ));
};

export default ContactRowList;

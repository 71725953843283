import React, { useState, useContext, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useAuth } from "../contexts/AuthProvider";
import ProfileContext from "../contexts/ProfileContext";
import ResourceContext from "../contexts/ResourceContext";
import FlagContext from "../contexts/FlagContext";
import axios from "axios";
import { supabase } from "../services/supabase/supabaseClient";
import { Navigate } from "react-router-dom";

import EditPerkView from "../components/views/EditPerkView";
import ProfileView from "../components/views/ProfileView";
import AccountView from "../components/views/AccountView";
import ManageSubscriptionView from "../components/views/ManageSubscriptionView";
import PartnerSideNav from "../components/PartnerSideNav";
import RedemptionsDashboardView from "../components/views/Dashboards/RedemptionsDashboardView";
import NetworkPartnerDashboardView from "../components/views/Dashboards/NetworkPartnerDashboardView";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  const { session, user, signOut } = useAuth();
  const [profile, profileDispatch] = useContext(ProfileContext);
  const [isActive, setIsActive] = useState(false);
  const [resource, resourceDispatch] = useContext(ResourceContext);
  const [flags, flagDispatch] = useContext(FlagContext);
  const [isSignOutSuccessful, setSignOutSuccessful] = useState(false);
  const [perkTierVisible, setPerkTierVisible] = useState(false);
  const [highlightedItem, setHighlightedItem] = useState("Redemptions");
  const [submissions, setSubmissions] = useState([]);
  const [rankings, setRankings] = useState([]);

  useEffect(() => {
    if (profile && profile.profile && profile.profile.subscription_end_date) {
      setIsActive(new Date(profile.profile.subscription_end_date) > new Date());
    }
  }, [profile]);

  const subscriptionsFlag = flags?.flags.some(
    (flag) => flag.flag_id === "eb41a64a-940c-4f79-aa67-3d59f9601b44"
  );

  const handleItemClick = (item) => {
    setHighlightedItem(item);
  };

  const handleSignOut = async (event) => {
    event.preventDefault();

    try {
      await signOut();
      setSignOutSuccessful(true);
    } catch (error) {
      console.log(`error signing out`);
    }
  };

  if (isSignOutSuccessful) {
    return <Navigate to="/partners" />;
  }

  const fetchPerkTiers = async (organizationIds) => {
    try {
      const { data: userPerkTiers, error: userPerkTiersError } = await supabase
        .from("user_perk_tiers")
        .select("perk_tier_id, perk_tiers!inner(*), user_id")
        .eq("perk_tiers.hubspot_form_id", user.user_metadata.perk_form_id)
        .in("user_id", organizationIds);

      if (userPerkTiersError) {
        console.error("Error fetching user perk tiers:", userPerkTiersError);
        return [];
      }

      return userPerkTiers;
    } catch (error) {
      console.error("Error in fetching perk tiers:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchFormSubmissions = async () => {
      try {
        const token = session.access_token;

        // Fetch hubspot submissions
        const response = await axios.get(
          `https://app.launchbook.com/.netlify/functions/hubspotFormSubmissions?formId=${session.user.user_metadata.perk_form_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Fetch launchbook submissions to associate Network Partner
        const { data: supabaseData, error } = await supabase
          .from("submissions")
          .select(
            "created_at, email, form_id, profiles ( id, organization_name, organization_type, image_url, network_size, country, state_us_only, city )"
          )
          .eq("form_id", user.user_metadata.perk_form_id);

        if (error) {
          throw error;
        }

        // Format Supabase data
        const supabaseFormatted = supabaseData.map((submission) => ({
          createdAt: new Date(submission.created_at).toLocaleDateString(),
          email: submission.email,
          organizationId: submission.profiles.id,
          organizationName: submission.profiles.organization_name,
          organizationType: submission.profiles.organization_type,
          imageUrl: submission.profiles.image_url,
          networkSize: submission.profiles.network_size,
          country: submission.profiles.country,
          state_us_only: submission.profiles.state_us_only,
          city: submission.profiles.city,
        }));

        // Does the CP have any perk tiers?
        const { data: perkTiersCheck, error: perkTiersCheckError } =
          await supabase
            .from("perk_tiers")
            .select("id")
            .eq("hubspot_form_id", user?.user_metadata.perk_form_id);

        if (perkTiersCheckError) {
          throw perkTiersCheckError;
        }

        // Does GVN have a perk_tier of theirs enabled?
        // We coalesce GVN to unmatched Launchbook redemptions, and thus need to coalesce
        // GVN's perk_tier value on top of these.
        const { data: gvnTierCheck, error: gvnTierError } = await supabase
          .from("user_perk_tiers")
          .select("perk_tier_id, perk_tiers!inner(*), user_id")
          .eq("perk_tiers.hubspot_form_id", user.user_metadata.perk_form_id)
          .eq("user_id", "b4003544-9790-434b-9a2a-845ef4b09457");

        if (gvnTierError) {
          throw gvnTierError;
        }

        // If CP has perk_tiers, merge relevant perk tier data
        if (perkTiersCheck.length) {
          const organizationIds = supabaseFormatted.map(
            (submission) => submission.organizationId
          );

          const userPerkTiers = await fetchPerkTiers(organizationIds);

          supabaseFormatted.forEach((submission) => {
            const perkTierData = userPerkTiers.filter(
              (perkTier) => perkTier.user_id === submission.organizationId
            );

            submission.perks = perkTierData.length > 0 ? perkTierData : [];
          });

          setPerkTierVisible(true);
        }

        // Transform Hubspot data and merge it with Supabase data
        const submissions = response.data;
        const currentYear = new Date().getFullYear();

        const formattedSubmissions = submissions.map((submission) => {
          const emailObj = submission.values.find(
            (item) => item.name === "email"
          );
          const firstNameObj = submission.values.find(
            (item) => item.name === "firstname"
          );
          const lastNameObj = submission.values.find(
            (item) => item.name === "lastname"
          );
          const companyObj = submission.values.find(
            (item) => item.name === "name"
          );
          const countryObj = submission.values.find(
            (item) => item.name === "gvn_country"
          );
          const teamSizeObj = submission.values.find(
            (item) => item.name === "team_size"
          );
          const yearFoundedObj = submission.values.find(
            (item) => item.name === "gvn___year_founded"
          );
          const websiteUrlObj = submission.values.find(
            (item) => item.name === "website"
          );

          const submittedAt = new Date(
            submission.submittedAt
          ).toLocaleDateString();
          const email = emailObj ? emailObj.value : null;
          const firstName = firstNameObj ? firstNameObj.value : null;
          const lastName = lastNameObj ? lastNameObj.value : null;
          const company = companyObj ? companyObj.value : null;
          const country = countryObj ? countryObj.value : null;
          const websiteUrl = websiteUrlObj ? websiteUrlObj.value : null;
          const teamSize = teamSizeObj ? parseInt(teamSizeObj.value) : null;
          const yearFounded = yearFoundedObj
            ? parseInt(yearFoundedObj.value)
            : null;
          const age = yearFounded ? currentYear - yearFounded : null;

          // Find matching Supabase record
          const supabaseRecord = supabaseFormatted.find(
            (record) =>
              record.email === email && record.createdAt === submittedAt
          );

          return {
            submittedAt,
            email,
            firstName,
            lastName,
            company,
            country,
            teamSize,
            yearFounded,
            age,
            websiteUrl,
            networkPartner: supabaseRecord
              ? supabaseRecord.organizationName
              : "Global Venture Network",
            networkPartnerId: supabaseRecord
              ? supabaseRecord.organizationId
              : "b4003544-9790-434b-9a2a-845ef4b09457",
            networkPartnerType: supabaseRecord
              ? supabaseRecord.organizationType
              : "Startup Community",
            networkPartnerImageUrl: supabaseRecord
              ? supabaseRecord.imageUrl
              : "https://wakrzlxbvlvorkygeuzu.supabase.co/storage/v1/object/public/network_partners/image_urls/gvn.png?t=2024-06-26T20%3A18%3A40.303Z",
            networkPartnerSize: supabaseRecord
              ? supabaseRecord.networkSize
              : 30000,
            networkPartnerCountry: supabaseRecord
              ? supabaseRecord.country
              : "United States",
            networkPartnerState: supabaseRecord
              ? supabaseRecord.state_us_only
              : "Colorado",
            networkPartnerCity: supabaseRecord ? supabaseRecord.city : "Denver",
            // if the CP has perk_tiers, see whether the organizationId where the submission occurred has a perk_tier association
            // if no association (meaning outside Launchbook, associate GVN's perk_tier information)
            ...(perkTiersCheck.length && supabaseRecord
              ? { perks: supabaseRecord.perks }
              : perkTiersCheck.length && !supabaseRecord
              ? { perks: gvnTierCheck }
              : {}),
          };
        });

        setSubmissions(formattedSubmissions);

        const fetchGlobalRankings = async () => {
          try {
            const response = await axios.get(
              "/.netlify/functions/getGlobalRankings",
              // "https://app.launchbook.com/.netlify/functions/getGlobalRankings",
              {
                headers: {
                  Authorization: `Bearer ${session.access_token}`,
                  "Content-Type": "application/json",
                },
              }
            );

            const rankings = response.data;

            // Fetch and embed perk tiers in the rankings data, similar to submissions
            if (perkTiersCheck.length) {
              const rankingOrganizationIds = rankings.map(
                (ranking) => ranking.organizationId
              );
              const rankingPerkTiers = await fetchPerkTiers(
                rankingOrganizationIds
              );

              rankings.forEach((ranking) => {
                const perksForRanking = rankingPerkTiers.filter(
                  (perk) => perk.user_id === ranking.organizationId
                );
                ranking.perks =
                  perksForRanking.length > 0 ? perksForRanking : [];
              });
            }

            setRankings(rankings);
          } catch (error) {
            console.error("Error fetching global rankings:", error);
          }
        };

        fetchGlobalRankings();
      } catch (error) {
        console.error("Error fetching form submissions:", error);
      }
    };

    if (session && flags) {
      fetchFormSubmissions();
    }
  }, [session, flags]);

  const renderComponent = () => {
    // Check if subscriptionFlag exists
    if (subscriptionsFlag && !isActive) {
      // Check if highlightedItem is not "Account" or "Profile"
      if (highlightedItem !== "Account" && highlightedItem !== "Profile") {
        return <ManageSubscriptionView handleItemClick={handleItemClick} />;
      }
    }

    switch (highlightedItem) {
      case "Redemptions":
        return (
          <RedemptionsDashboardView
            contacts={submissions}
            perkTierVisible={perkTierVisible}
          />
        );
      case "Network Partners":
        return (
          <NetworkPartnerDashboardView
            submissions={submissions}
            rankings={rankings}
            perkTierVisible={perkTierVisible}
          />
        );
      case "Edit Perk":
        return <EditPerkView perkTierVisible={perkTierVisible} />;
      case "Profile":
        return <ProfileView />;
      case "Account":
        return <AccountView />;
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className="overflow-hidden min-h-full">
        <PartnerSideNav
          email={user.email}
          handleSignOut={handleSignOut}
          highlightedItem={highlightedItem}
          handleItemClick={handleItemClick}
          subscriptionsFlag={subscriptionsFlag}
        />
        {renderComponent()}
      </section>
    </React.Fragment>
  );
}
